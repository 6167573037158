import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {ApiService} from './api.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {PreSignedUrlResponse} from '../../app.datatypes';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class S3UploadService {
  progress: number;

  constructor(private apiService: ApiService, private httpService: HttpClient) {
  }

  public getPreSignedURL(
    fileName: string,
    contentType: string,
    bucketType: string
  ): Observable<PreSignedUrlResponse> {
    let url = `api/get-pre-signed-url?fileName=${fileName}&contentType=${contentType}`;

    if (bucketType) {
      url += `&bucketType=${bucketType}`;
    }

    return this.apiService.get(url);
  }

  public uploadToS3(preSignedUrl: string, file: Blob): Observable<any> {
    this.progress = 1;

    return this.httpService
      .put(preSignedUrl, file, {
        reportProgress: true,
        observe: 'events',
        headers: {'ngsw-bypass': 'true'},
      })
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type === HttpEventType.Response) {
            this.progress = null;
          }
          return this.progress;
        }),
        catchError((err: any) => {
          this.progress = null;
          return throwError(err.message);
        })
      );
  }
}
